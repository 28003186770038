import { ExternalLinkIcon } from '@chakra-ui/icons'
import { Box, Flex, Heading, HStack, Link, VStack } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { LiquidityDistribution } from '@traderjoe-xyz/sdk-v2'
import React from 'react'

import DistributionOptions from './DistributionOptions'

interface LiquidityShapePickerProps {
  distribution: LiquidityDistribution
  onDistributionChange: (distribution: LiquidityDistribution) => void
}

const LiquidityShapePicker = ({
  distribution,
  onDistributionChange
}: LiquidityShapePickerProps) => {
  return (
    <VStack
      data-cy="liquidity-shape-picker"
      align="flex-start"
      spacing={4}
      w="full"
    >
      <Flex w="full" align="center" justify="space-between">
        <Heading size="md">
          <Trans>Choose Liquidity Shape</Trans>
        </Heading>
        <Link
          isExternal
          aria-label="Liquidity strategies for LFJ Liquidity Book"
          href={`https://support.${window.location.host}/en/articles/6707938-liquidity-strategies`}
          color="accent.500"
          fontSize="sm"
          fontWeight="semibold"
          flexShrink={0}
        >
          <HStack spacing={1}>
            <Box as="span">
              <Trans>Learn more</Trans>
            </Box>
            <ExternalLinkIcon color="accent.500" />
          </HStack>
        </Link>
      </Flex>
      <DistributionOptions
        distribution={distribution}
        onDistributionChange={onDistributionChange}
      />
    </VStack>
  )
}

export default LiquidityShapePicker
