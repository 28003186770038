import { Currency, Percent, Token, TokenAmount } from '@traderjoe-xyz/sdk-core'
import {
  getBidAskDistributionFromBinRange,
  getCurveDistributionFromBinRange,
  getUniformDistributionFromBinRange,
  LiquidityDistribution,
  LiquidityDistributionParams,
  normalizeDist
} from '@traderjoe-xyz/sdk-v2'
import { WEI_PER_ETHER } from 'constants/bigint'
import { Pool as DexbarnPool } from 'types/dexbarn'
import { LBPool, LBPoolReward, LBPoolRewards, LBPoolVersion } from 'types/pool'
import {
  BinVolumeData,
  LBPairDistribution,
  LBPairUserBalances
} from 'types/poolV2'
import { RewardsInfo } from 'types/rewards'
import { formatEther, formatUnits, parseEther } from 'viem'

import { getPriceFromBinId } from './bin'
import { getPoolPoints } from './points'
import { computeAndParsePriceFromBin } from './prices'
import { getCurrencyAmount } from './swap'

export const convertLBPositionsToUserLBPositions = ({
  amounts,
  binIds,
  lbBinStep,
  liquidities,
  token0Decimals,
  token1Decimals
}: {
  amounts: {
    amountsX: readonly bigint[]
    amountsY: readonly bigint[]
  }
  binIds: number[]
  lbBinStep: number
  liquidities: readonly bigint[]
  token0Decimals: number
  token1Decimals: number
}) => {
  const userBalances: LBPairUserBalances = {
    amounts: [],
    liquidity: [],
    positions: [],
    prices: []
  }

  const amountsX = amounts.amountsX
  const amountsY = amounts.amountsY

  binIds.forEach((binId, i) => {
    const amountRawX = amountsX[i].toString()
    const amountRawY = amountsY[i].toString()
    const amountX = parseFloat(formatUnits(amountsX[i], token0Decimals))
    const amountY = parseFloat(formatUnits(amountsY[i], token1Decimals))

    const isCursed =
      (amountX === 1e-18 && amountY === 0) ||
      (amountX === 0 && amountY === 1e-18) ||
      (amountX === 0 && amountY === 0)

    const price = parseFloat(
      getPriceFromBinId(binId, lbBinStep, token0Decimals, token1Decimals, 18)
    )

    if (!isCursed) {
      userBalances.positions.push(binId)
      userBalances.liquidity.push(liquidities[i].toString())
      userBalances.prices.push(price)
      userBalances.amounts.push({
        amountX: amountX < 0 ? 0 : amountX,
        amountY: amountY < 0 ? 0 : amountY,
        rawAmountX: !amountRawX.includes('-') ? amountRawX : '0',
        rawAmountY: !amountRawY.includes('-') ? amountRawY : '0'
      })
    }
  })

  return userBalances
}

export const inverseLBPairDistributions = (
  pairDistributions: LBPairDistribution[]
): LBPairDistribution[] => {
  return pairDistributions.map((distribution) => ({
    ...distribution,
    price: (1 / Number(distribution.price)).toFixed(18)
  }))
}

export const inverseBinsData = (bins: BinVolumeData[]): BinVolumeData[] => {
  return bins.map((bin) => ({
    ...bin,
    priceXY: 1 / bin.priceXY,
    priceYX: 1 / bin.priceYX
  }))
}

interface ConvertLBPositionToLiquidityChartDataProps {
  activeBinId: number
  binStep: string
  highlightedBins?: number[]
  radius?: number
  token0?: Token
  token1?: Token
  userBalances?: LBPairUserBalances
}

export const convertLBPositionToLiquidityChartData = ({
  activeBinId,
  binStep,
  highlightedBins,
  radius = Number.MAX_SAFE_INTEGER,
  token0,
  token1,
  userBalances
}: ConvertLBPositionToLiquidityChartDataProps) => {
  try {
    if (!userBalances || !token0 || !token1) {
      return []
    }

    const activePrice = Number(
      computeAndParsePriceFromBin(
        activeBinId,
        Number(binStep),
        token0,
        token1,
        token1.decimals
      )
    )

    const userData = userBalances.positions
      .filter((binId) => Math.abs(binId - activeBinId) <= radius)
      .map((binId, i) => {
        const amountX = userBalances.amounts[i].amountX
        const amountY = userBalances.amounts[i].amountY
        const rawAmountX = userBalances.amounts[i].rawAmountX
        const rawAmountY = userBalances.amounts[i].rawAmountY
        const price = userBalances.prices[i]
        const amountYRatio =
          amountY > 0 || amountX > 0 ? amountY / (amountX * price + amountY) : 0

        const valueX = amountX * activePrice
        const valueY = amountY

        return {
          amountX: new TokenAmount(token0, rawAmountX),
          amountY: new TokenAmount(token1, rawAmountY),
          amountYPct: (amountYRatio * 100).toFixed(2),
          binId,
          isActiveBin: binId === activeBinId,
          liquidity: valueX + valueY,
          price: `${computeAndParsePriceFromBin(
            binId,
            Number(binStep),
            token0,
            token1,
            token1.decimals
          )}`
        }
      })

    // insert highlighted bins
    let bars = [...userData]
    if (userData.length > 0) {
      highlightedBins?.forEach((binId) => {
        if (Math.abs(binId - activeBinId) <= radius) {
          const userDataIndex = userData.findIndex(
            (data) => data.binId === binId
          )
          if (userDataIndex === -1) {
            bars.push({
              amountX: new TokenAmount(token0, '0'),
              amountY: new TokenAmount(token0, '0'),
              amountYPct: '0',
              binId,
              isActiveBin: binId === activeBinId,
              liquidity: 1e-18,
              price: `${computeAndParsePriceFromBin(
                binId,
                Number(binStep),
                token0,
                token1,
                token1.decimals
              )}`
            })
          }
        }
      })
    }
    bars = bars.sort((a, b) => a.binId - b.binId)

    // insert empty bins within radius range
    const finalData: LBPairDistribution[] = []
    bars.forEach((data, i) => {
      let thisBinId =
        i === 0
          ? Math.max(activeBinId - radius, data.binId)
          : finalData[finalData.length - 1].binId + 1
      const maxBinId = Math.min(data.binId, activeBinId + radius)
      while (i !== 0 && thisBinId < maxBinId) {
        finalData.push({
          amountX: new TokenAmount(token0, '0'),
          amountY: new TokenAmount(token0, '0'),
          amountYPct: '0',
          binId: thisBinId,
          isActiveBin: thisBinId === activeBinId,
          liquidity: 0,
          price: `${computeAndParsePriceFromBin(
            thisBinId,
            Number(binStep),
            token0,
            token1,
            token1.decimals
          )}`
        })
        thisBinId += 1
      }
      if (Math.abs(data.binId - activeBinId) <= radius) {
        finalData.push(data)
      }
    })
    return finalData
  } catch {
    return []
  }
}

export const convertRewardsInfoToLBPoolReward = (
  rewardsInfo: RewardsInfo[],
  poolLiquidityUsd?: number
): LBPoolRewards => {
  let rewardsApr = 0

  const rewards: LBPoolReward[] = rewardsInfo
    .filter((reward) => reward.rewardPerDay > 0)
    .map((reward) => {
      const rewardsPerDayUsd = reward.rewardsPerDayUsd || 0

      if (poolLiquidityUsd) {
        rewardsApr += (rewardsPerDayUsd / poolLiquidityUsd) * 365
      }

      return {
        rewardPerDay: reward.rewardPerDay,
        rewardToken: reward.sdkToken
          ? {
              address: reward.sdkToken.isToken
                ? reward.sdkToken.address
                : undefined,
              decimals: reward.sdkToken.decimals,
              symbol: reward.sdkToken.symbol || ''
            }
          : undefined,
        rewardsPerDayUsd
      }
    })

  return {
    apr: rewardsApr,
    epoch: {
      end: rewardsInfo[0].end,
      start: rewardsInfo[0].start,
      status: rewardsInfo[0].status,
      value: rewardsInfo[0].epoch
    },
    rewards
  }
}

export const convertDexbarnPoolToPool = (
  pool: DexbarnPool,
  epochRewards: RewardsInfo[][]
): LBPool => {
  const poolRewards = epochRewards
    .map((rewards) =>
      rewards.filter(
        (reward) =>
          reward.poolId.toLowerCase() === pool.pairAddress.toLowerCase()
      )
    )
    .filter(
      (rewards) => rewards.length > 0 && rewards.some((r) => r.rewardPerDay > 0)
    )

  const hasAtLeastOneOngoingEpoch = poolRewards.some((rewards) =>
    rewards.some((reward) => reward.status === 'ongoing')
  )

  let lbPoolVersion: LBPoolVersion
  switch (pool.version) {
    case 'v2.0':
      lbPoolVersion = 'v2'
      break
    case 'v2.1':
      lbPoolVersion = 'v21'
      break
    case 'v2.2':
      lbPoolVersion = 'v22'
      break
  }

  return {
    apr: (pool.feesUsd * 365) / pool.liquidityUsd,
    feePct: Math.round(pool.lbBaseFeePct * 200) / 200, // round to nearest 0.005%
    feesUsd: pool.feesUsd,
    isMigrated: pool.status === 'old',
    lbBinStep: pool.lbBinStep,
    lbMaxFeePct: pool.lbMaxFeePct,
    lbPoolVersion,
    liquidityDepthTokenX: pool.liquidityDepthTokenX,
    liquidityDepthTokenY: pool.liquidityDepthTokenY,
    liquidityUsd: pool.liquidityUsd,
    liquidityUsdDepthMinus: pool.liquidityDepthMinus,
    liquidityUsdDepthPlus: pool.liquidityDepthPlus,
    name: pool.name,
    pairAddress: pool.pairAddress,
    points: getPoolPoints(pool.pairAddress),
    protocolFeePct: pool.protocolSharePct ?? 0,
    rewards:
      poolRewards.length > 0 && hasAtLeastOneOngoingEpoch
        ? poolRewards.map((rewards) =>
            convertRewardsInfoToLBPoolReward(rewards, pool.liquidityUsd)
          )
        : undefined,
    tokenX: pool.tokenX,
    tokenY: pool.tokenY,
    volumeUsd: pool.volumeUsd
  }
}

// convert delta ids, distributionX, distributionY to an array of LBPairDistribution
export const convertDistributionParamsToLiquidityDistribution = ({
  activeBinId,
  binStep,
  distributionParams,
  token0,
  token1,
  totalAmount0,
  totalAmount1
}: {
  activeBinId?: number
  binStep?: string
  distributionParams?: LiquidityDistributionParams
  token0?: Token
  token1?: Token
  totalAmount0?: bigint
  totalAmount1?: bigint
}): LBPairDistribution[] | undefined => {
  if (!distributionParams || !activeBinId || !token0 || !token1 || !binStep) {
    return undefined
  }
  const { deltaIds, distributionX, distributionY } = distributionParams

  try {
    const sumDistributionX = distributionX.reduce((a, b) => a + b, BigInt(0))
    const sumDistributionY = distributionY.reduce((a, b) => a + b, BigInt(0))

    const results = deltaIds.map((deltaId, i) => {
      const binId = activeBinId + deltaId
      const price = computeAndParsePriceFromBin(
        binId,
        Number(binStep),
        token0,
        token1,
        token1.decimals
      )
      const parsedPrice = parseEther(price as `${number}`)

      const amountXFactor =
        sumDistributionX > BigInt(0)
          ? (distributionX[i] * BigInt(10000)) / sumDistributionX
          : BigInt(0)
      const amountYFactor =
        sumDistributionY > 0
          ? (distributionY[i] * BigInt(10000)) / sumDistributionY
          : BigInt(0)
      const amountX = totalAmount0
        ? (totalAmount0 * amountXFactor) / BigInt(10000)
        : BigInt(0)
      const amountY = totalAmount1
        ? (totalAmount1 * amountYFactor) / BigInt(10000)
        : BigInt(0)

      const scaledAmountY =
        (amountY * WEI_PER_ETHER) / BigInt(10) ** BigInt(token1.decimals)
      const scaledAmountX =
        (amountX * parsedPrice) / BigInt(10) ** BigInt(token0.decimals)
      const liquidity = scaledAmountX + scaledAmountY

      const amountYPct =
        liquidity > 0 ? new Percent(scaledAmountY, liquidity).toFixed(0) : '0'

      return {
        amountX: new TokenAmount(token0, amountX.toString()),
        amountY: new TokenAmount(token1, amountY.toString()),
        amountYPct,
        binId,
        isActiveBin: binId === activeBinId,
        liquidity: parseFloat(formatEther(liquidity)),
        price
      }
    })

    // add empty bins
    for (
      let deltaId = deltaIds[0];
      deltaId <= deltaIds[deltaIds.length - 1];
      deltaId++
    ) {
      const binId = activeBinId + deltaId

      if (results.findIndex((result) => result.binId === binId) >= 0) {
        continue
      }

      results.push({
        amountX: new TokenAmount(token0, '0'),
        amountY: new TokenAmount(token1, '0'),
        amountYPct: '0',
        binId,
        isActiveBin: binId === activeBinId,
        liquidity: 0,
        price: computeAndParsePriceFromBin(
          binId,
          Number(binStep),
          token0,
          token1,
          token1.decimals
        )
      })
    }

    return results.sort((a, b) => a.binId - b.binId)
  } catch (err) {
    console.error(err)
    return undefined
  }
}

export const getAddLiquidityDistributionParams = ({
  activeBinId,
  alpha,
  amount0,
  amount1,
  binRange,
  currency0,
  currency1,
  liquidityDistribution
}: {
  liquidityDistribution: LiquidityDistribution
  activeBinId?: number
  alpha?: number
  amount0?: bigint
  amount1?: bigint
  binRange?: number[]
  currency0?: Currency
  currency1?: Currency
}): LiquidityDistributionParams | undefined => {
  const currencyAmount0 = getCurrencyAmount(currency0, amount0)
  const currencyAmount1 = getCurrencyAmount(currency1, amount1)

  if (!activeBinId || !binRange || !currencyAmount0 || !currencyAmount1) {
    return undefined
  }

  let distributionParams: LiquidityDistributionParams | undefined
  try {
    distributionParams =
      liquidityDistribution === LiquidityDistribution.BID_ASK
        ? getBidAskDistributionFromBinRange(activeBinId, binRange, [
            currencyAmount0,
            currencyAmount1
          ])
        : liquidityDistribution === LiquidityDistribution.CURVE
          ? getCurveDistributionFromBinRange(
              activeBinId,
              binRange,
              [currencyAmount0, currencyAmount1],
              alpha
            )
          : getUniformDistributionFromBinRange(activeBinId, binRange)
  } catch (e) {
    console.error(e)
    // getUniformDistributionFromBinRange may throw an error when the bin range is invalid
    // for example: the user enters a min price greater than max price
    distributionParams = undefined
  }

  const {
    deltaIds,
    distributionX: distributionX_,
    distributionY: distributionY_
  } = distributionParams || {}

  const sumX = distributionX_?.reduce((sum, cur) => sum + cur, BigInt(0))
  const sumY = distributionY_?.reduce((sum, cur) => sum + cur, BigInt(0))

  const distributionX =
    distributionX_ && sumX && sumX > 0
      ? normalizeDist(distributionX_, parseEther('1'), parseEther('1'))
      : distributionX_

  const distributionY =
    distributionY_ && sumY && sumY > 0
      ? normalizeDist(distributionY_, parseEther('1'), parseEther('1'))
      : distributionY_

  return deltaIds && distributionX && distributionY
    ? { deltaIds, distributionX, distributionY }
    : undefined
}
