import { Flex, Heading, Link, Text } from '@chakra-ui/react'
import PageHelmet from 'components/PageHelmet'
import React from 'react'

const TermsOfService = () => {
  return (
    <>
      <PageHelmet
        title="LFJ | Instant, Low-Cost Crypto Exchange for All Traders | Terms of Service"
        url="tos"
      />
      <Flex
        flexDir="column"
        gap={4}
        pt={4}
        maxW="960px"
        margin="0 auto"
        minH="calc(100vh - 80px)"
        px={{ base: 0, md: 4 }}
        pb={{ base: 8, md: 16 }}
      >
        <Heading
          textAlign="center"
          size="2xl"
          color="textPrimary"
          mb={{ base: 4, md: 8 }}
        >
          Terms and Conditions of Service
        </Heading>
        <Heading textAlign="center" as="h3">
          Updated 31 July 2022
        </Heading>
        <Text fontSize="md">
          These terms (<Text as="b">“Terms”</Text>) govern the use of our
          services (<Text as="b">“Services”</Text>), which include the use of
          Trader Joe’s website here (“Site”) that provides a user interface (
          <Text as="b">“Platform”</Text>) to access a decentralised protocol on
          the Avalanche blockchain, and any other software, apps, tools,
          features, or functionalities provided on or in connection with our
          services. To use our Services, you (<Text as="b">“User”</Text>,{' '}
          <Text as="b">“you”</Text> or <Text as="b">“your”</Text>) must agree to
          these Terms with Joemart Ltd (<Text as="b">“Joemart”</Text>,{' '}
          <Text as="b">“we”</Text>, <Text as="b">“us”</Text> or{' '}
          <Text as="b">“our”</Text>), which is the company that runs the Site.
        </Text>
        <Text fontSize="md">
          The Terms are a contract between you and us, and they explain the
          terms and conditions by which you may access and use the Services. By
          using the “connect to a wallet” interface on the Site, or using any of
          our Services, you agree that you have read, understand, and accept
          these Terms. The Terms include and should be read together with our
          Privacy Policy, which is available{' '}
          <Link aria-label="Trader Joe Privacy Policy" href="/privacy">
            here
          </Link>
          .
        </Text>
        <Text fontSize="md">
          <Text as="b">
            Note: Please read the Terms carefully. The Terms contain important
            information, including the risks you take in using our Services and
            the indemnity you provide to us. If a dispute arises between you and
            us that cannot be resolved, it is mandatory to use arbitration as a
            dispute resolution mechanism and you cannot initiate or participate
            in class action proceedings. You should only access the Services if
            you agree completely with these Terms.
          </Text>
        </Text>
        <Text fontSize="md">
          Our Services relate to Digital Assets, which refer to any digital
          representation of value based on, or built on top of, a cryptographic
          protocol of a computer network, including digital tokens that may be
          referred to as “cryptocurrency”, “digital payment token” or “virtual
          currency”. There are risks in trading or otherwise dealing in Digital
          Assets. We explain some of these risks at section 14.
        </Text>
        <Text fontSize="md">
          Your use of the Services is governed by the version of the Terms in
          effect on the date of use. We may make changes to the Terms from time
          to time without prior notice. If we do this, we will post the changed
          provisions on the Site, and the revised Terms shall be effective at
          such time. It is your sole responsibility to review the Terms from
          time to time to view such changes and to ensure that you understand
          the Terms that apply when you use the Services. If we make material
          changes to these Terms, we will use reasonable efforts to provide
          notice of such changes, such as by providing notice through the Site
          or Platform, and through other communications. If you do not agree
          with any such modification, your sole and exclusive remedy is to
          terminate your use of the Services. You understand and agree that your
          continued use of the Services or the Site after we have made any such
          changes constitutes your acceptance of the new Terms.
        </Text>
        <Text fontSize="md">
          Some Services described in these Terms or on the Site or Platform may
          not be available in all jurisdictions or to all Users. As our Services
          are evolving over time we may change or discontinue all or any part of
          the Services, at any time and without notice, at our sole discretion
          and without liability to you as a result.
        </Text>
        <Heading as="h3">Agreement to Terms</Heading>
        <Text fontSize="md">
          By using our Services, you agree to be bound by these Terms. If you do
          not agree to be bound by these Terms, then you may not access or use
          the Services.
        </Text>
        <Text fontSize="md">
          Your use of the specific Services may be subject to additional terms
          and policies as may be posted on the Site or Platform from time to
          time.
        </Text>
        <Text fontSize="md">
          Those additional terms and conditions, which are available with the
          relevant Services, then become part of your contract with us if you
          use those Services. In the event of a conflict between these Terms and
          any additional applicable terms we may provide for a specific Service,
          such additional terms shall control for that specific Service.
        </Text>
        <Heading as="h3">User Eligibility</Heading>
        <Text fontSize="md">
          Your ability to use our Services is subject to the following
          conditions:
          <ul>
            <li>You must be at least 18 years of age.</li>
            <li>
              You must not attempt to access or use the Services if you are not
              permitted to do so, whether by law or under these Terms. By using
              the Services, you affirm, represent and warrant that you have the
              right, authority and capacity to enter into these Terms, you meet
              all of the eligibility requirements, and your use of the Services
              shall not violate any applicable laws including those in your
              jurisdiction.
            </li>
            <li>
              You must not be a citizen of, or be located, organised or resident
              in the United States of America (“U.S.”) or the People’s Republic
              of China.
            </li>
            <li>
              You must not be subject to any sanctions program or be a citizen
              of, or located, organised or resident in a country or territory
              that is, or whose government is, the subject of sanctions programs
              that prohibit dealings with that country or territory. Sanctions
              programs refer to relevant sanction requirements or restricted
              party lists that are maintained by or issued under the U.S. Bank
              Secrecy Act, Uniting and Strengthening America by Providing
              Appropriate Tools Required to Intercept and Obstruct Terrorism Act
              (USA PATRIOT Act), U.S. Department of Treasury&apos;s Office of
              Foreign Assets Control (OFAC), U.S. Securities & Exchange
              Commission, U.S. Federal Bureau of Investigation, U.S. Central
              Intelligence Agency, U.S. Internal Revenue Service, Financial
              Crimes Enforcement Network (FinCEN), United Nations, North
              Atlantic Treaty Organisation, Organisation for Economic
              Cooperation and Development, Financial Action Task Force, or any
              other relevant governmental or non-governmental entity or agency.
              You must not be acting on behalf of or under the authority,
              instruction or employment of a company or other legal person that
              comes under the scope of sections 2(c) and 2(d) above.
            </li>
          </ul>
        </Text>
        <Heading as="h3">Services</Heading>
        <Text fontSize="md">
          The Services we may provide to you include the provision of a Platform
          on which Users may access decentralised finance (
          <Text as="b">“DeFi”</Text>) services such as lending, liquidity
          providing, trading and staking, and develop or launch their own DeFi
          applications. You may also purchase, use and trade in our token, known
          as JOE Token. More details on the JOE Token are set out in our
          documents here.
        </Text>
        <Heading as="h3">Your WALLET and equipment</Heading>
        <Text fontSize="md">
          To use some of our Services, you need a Metamask or other compatible
          Digital Asset wallet or address (<Text as="b">“Wallet”</Text>). This
          is how we identity you and your activity on our Platform (
          <Text as="b">“your linked Wallet”</Text>).
        </Text>
        <Text fontSize="md">
          You agree to be responsible for all action taken using your linked
          Wallet, whether authorised by you or not, until you notify us that
          your linked Wallet has been compromised. You agree to notify us
          immediately if you suspect your linked Wallet has been compromised or
          otherwise suspect any security issues related to your use of the
          Services.
        </Text>
        <Text fontSize="md">
          You may close your linked Wallet with us by disconnecting your Wallet
          from our Platform.
        </Text>
        <Text fontSize="md">
          Joemart may restrict, suspend, or close your linked Wallet according
          to our policy for handling copyright-related takedown requests, or if
          we reasonably believe that you have broken any rule in these Terms.
        </Text>
        <Text fontSize="md">
          <Text as="b">JOE</Text> Token or other Digital Assets that you
          purchase or use in relation to the Services may be held in one or more
          Wallets of yours. Joemart does not operate, maintain, control or have
          custody over any contents of your Wallet. We accept no responsibility
          for, or liability to, you in connection with your Wallet and make no
          representations or warranties regarding how the Service will operate
          with any specific Wallet. Any issues relating to your Wallet should be
          addressed to your Wallet provider. We are not responsible for any acts
          or omissions by you in connection with your Wallet as a result of your
          Wallet being compromised.
        </Text>
        <Text fontSize="md">
          You must provide all equipment, connectivity, and software necessary
          to connect to the Service. You are solely responsible for any costs
          and expenses, including Internet connection or mobile fees, which you
          incur when accessing the Services.
        </Text>
        <Heading as="h3">YOUR CONTENT</Heading>
        <Text fontSize="md">
          The Service, Site and Platform contain content that may include text,
          files, documents, graphics, images, designs, music, software, audio
          and video (<Text as="b">“Content”</Text>). The Service and all Content
          contained therein or made available through the Service, including
          without limitation the Trader Joe logo and all designs, text,
          graphics, pictures, information, data, software, sound files, other
          files and the selection and arrangement thereof (collectively,{' '}
          <Text as="b">“Trader Joe’s Content”</Text>) belong to us or our
          licensors. We grant you a limited licence to use such Content as set
          out in section 10.
        </Text>
        <Text fontSize="md">
          You are solely responsible for and you own all intellectual property
          rights to your own Content that you may legally post on or submit to
          the Platform or Site (<Text as="b">“Your Content”</Text>). You provide
          to us a licence to store, copy, publish and analyse Your Content for
          the purpose of providing our Services, such as to publish Your Content
          to other Users. We may do so without notice and without compensation
          to you, and we are not obliged to do so in any event. You agree that
          any feedback provided to us can be used at our discretion in any
          manner.
        </Text>
        <Text fontSize="md">
          When Your Content is removed from the Platform or Site, our licence
          ends when the last copy disappears from Joemart’s backups, caches and
          other systems, unless other licences have been provided in relation to
          Your Content, such as a Creative Common licence.
        </Text>
        <Heading as="h3">Prohibited Activities</Heading>
        <Text fontSize="md">
          You agree and acknowledge that you will not do, or attempt to do, the
          following (<Text as="b">“Prohibited Activities”</Text>) without our
          express prior written permission in each case:
          <ul>
            <li>
              Use, display, mirror or frame the Services or any individual
              element within the Services, Trader Joe’s name, any Trader Joe
              trademark, logo or other proprietary information, or the layout
              and design of any page or form contained on a page, without our
              express written consent;
            </li>
            <li>
              Modify, distort, mutilate, or perform any other modification to
              Trader Joe’s Content which would be prejudicial to Trader Joe’s
              brand and/or reputation;
            </li>
            <li>
              Attempt to trademark, copyright, or otherwise acquire additional
              intellectual property rights in or to Trader Joe’s Content;
            </li>
            <li>
              Circumvent or attempt to circumvent any Fees (as defined in
              section 12) owed to us;
            </li>
            <li>
              Attempt to hack or hack our Services or any of our Users, or
              upload any computer viruses including worms, Trojan horses,
              corrupted files, cancelbots, or any other similar software or
              programs which may damage the operation of another’s computer or
              property or interfere with the Services;
            </li>
            <li>
              Attempt to access or search the Services or download Content from
              the Site or Platform using any engine, software, tool, agent,
              device or mechanism (including spiders, robots, crawlers,
              data-mining tools or the like) other than the software and/or
              search agents provided by Joemart or other generally available
              third-party web browsers;
            </li>
            <li>
              Attempt to decipher, decompile, disassemble or reverse engineer
              any of the software used to provide the Services;
            </li>
            <li>
              Collect or store any personally identifiable information from
              other Users without their express permission; Use the Services to
              post or transmit any unauthorised Content including, but not
              limited to, material that is, in our opinion, likely to cause
              annoyance, or which is defamatory, racist, obscene, threatening,
              or pornographic or is otherwise detrimental to or in violation of
              applicable laws, our systems or a third party’s systems or network
              security;
            </li>
            <li>
              Use Trader Joe’s Content in connection with images, videos, or
              other forms of media that depict hatred, intolerance, violence,
              cruelty, terrorism or anything else that could reasonably be found
              to constitute hate speech or otherwise infringe upon the rights of
              others;
            </li>
            <li>
              Communicate or interact with the Services in a manner that wrongly
              implies that we have sponsored or approved Your Content;
            </li>
            <li>
              Use the Services or Trader Joe’s Content for any commercial
              purpose (such as incorporating such Content in videos for
              commercial purposes) or for the benefit of any third party (such
              as to market or sell any third party product or service that
              include or contain Trader Joe’s Content);
            </li>
            <li>
              Impersonate or misrepresent your affiliation with any person or
              entity;
            </li>
            <li>
              Use the Services, including JOE Token or Digital Assets that are
              supported on the Platform, for the purpose of concealing economic
              activity, laundering money, financing terrorism or fraud;
            </li>
            <li>Engage in deceptive or manipulative trading activities;</li>
            <li>
              Use the Services to conduct financial activities subject to
              registration or licensing including, but not limited to, the
              creation and trading of securities, commodities, options, debt
              instruments or other financial instruments;
            </li>
            <li>
              Use the Services by or on behalf of our competitor for the purpose
              of interfering with the Services to obtain a competitive
              advantage;
            </li>
            <li>Distribute spam to other Users;</li>
            <li>
              Violate any applicable law or regulation; Use the Services or
              Trader Joe’s Content in any manner which, in our sole discretion,
              would be prejudicial to Trader Joe’s brand or reputation;
            </li>
            <li>
              Encourage or enable any other person to do any of the foregoing.
            </li>
          </ul>
          If you become aware of any use of the Services that is in violation of
          any of the terms specified in this section, please contact us at our
          official Discord channel to report it.
        </Text>
        <Heading as="h3">No Buy-Back, Redemption or Burning</Heading>
        <Text fontSize="md">
          We do not represent, warrant or undertake that we will buy back,
          redeem, burn or provide any other similar features in relation to JOE
          Token or other Digital Assets. However, we may do any of the foregoing
          at our discretion. We are not required to warn any User or third party
          when we decide to exercise this right.
        </Text>
        <Heading as="h3">No Ownership Interest, Claim or Loan</Heading>
        <Text fontSize="md">
          You understand and agree that your purchase, sale, holding, receipt or
          use of JOE Tokens, other Digital Assets or other assets from the
          Services does not:
          <ul>
            <li>
              Represent or constitute a loan or a contribution of capital to, or
              other investment in Joemart or any other entity that directly or
              indirectly controls, is controlled by, or is under common control
              with, Joemart (<Text as="b">“Affiliates”</Text>);
            </li>
            <li>
              Provide you with any ownership interest, equity, security, or
              right to or interest in the assets, rights, properties, revenues
              or profits of, or voting rights whatsoever in Joemart or its
              Affiliates; and
            </li>
            <li>
              Create or imply or entitle you to the benefits of any fiduciary or
              other agency relationship between Joemart, its Affiliates, or any
              of its directors, officers, employees, or agents.
            </li>
          </ul>
        </Text>
        <Heading as="h3">USER REPRESENTATIONS AND WARRANTIES</Heading>
        <Text fontSize="md">
          You represent and warrant that:
          <ul>
            <li>
              If you use the Services on behalf of a company or other entity
              then “you” includes you and that entity, and you represent and
              warrant that (i) you are an authorised representative of the
              entity with the authority to bind the entity to these Terms, and
              (ii) you agree to these Terms on the entity’s behalf;
            </li>
            <li>
              You are knowledgeable, experienced and sophisticated in using
              blockchain technology, entering blockchain-based transactions and
              in using our Services;
            </li>
            <li>
              You have made enquiries and are satisfied as to the legitimacy,
              authenticity and lawfulness of your right to acquire ownership,
              resell or otherwise deal with Digital Assets;
            </li>
            <li>
              You will not accept, solicit, offer, or engage with other Users,
              transact on or off the Platform or otherwise engage with smart
              contracts, with the intent to artificially devalue, inflate, or
              otherwise deceptively influence, misrepresent, or cause to be
              misrepresented the price of JOE Token or other Digital Assets; and
            </li>
            <li>
              You will not engage in any unlawful or deceptive conduct that may
              prevent competitive or fair trading, artificially inflate or
              deflate the price of JOE Token or other Digital Assets, simulate
              demand for any Digital Asset, or any other anti-competitive
              practices.
            </li>
          </ul>
        </Text>
        <Heading as="h3">Licence AND INTELLECTUAL PROPERTY</Heading>
        <Text fontSize="md">
          Unless otherwise indicated in writing by us, Trader Joe’s Content is
          the proprietary property of Joemart, its Affiliates, or our licensors,
          as applicable. You may not use any Trader Joe’s Content to link to the
          Service without our express written permission. You may not use
          framing techniques, metatags or other ‘hidden text’ to enclose any
          Trader Joe’s Content without our express written consent.
        </Text>
        <Text fontSize="md">
          Joemart, our Affiliates and our licensors exclusively own all right,
          title and interest in and to the Service, including all associated
          intellectual property rights therein. You acknowledge that the Service
          is protected by trademark, copyright and other laws. The appearance
          and style of the Site and Platform constitute the trademark of
          Joemart, our Affiliates and licensors, and may not be copied, imitated
          or used in whole or in part without our express written permission.
          You agree not to remove, alter or obscure any copyright, trademark,
          service mark or other proprietary rights notices incorporated in or
          accompanying the Service. You also agree that you will not apply for,
          register, or otherwise use or attempt to use any of our trademarks
          anywhere in the world without our prior written consent.
        </Text>
        <Text fontSize="md">
          It is our policy to terminate in appropriate circumstances Users who
          repeatedly infringe or are believed to be repeatedly infringing the
          intellectual property rights of Joemart, its Affiliates or third
          parties. Provided that you are eligible for use of the Services, and
          subject to your compliance with these Terms and applicable laws, you
          are granted a worldwide, non-exclusive, revocable, non-transferable,
          non-assignable, non-sub-licensable, royalty-free, limited and personal
          licence in relation to Trader Joe’s Content solely for your own use in
          connection with the Services (<Text as="b">“Licence”</Text>).
        </Text>
        <Text fontSize="md">
          Except for Your Content legally posted on the Site or Platform, you
          may not republish Trader Joe’s Content on any internet, intranet or
          extranet site or incorporate the Content in any other database or
          compilation. Any use of Trader Joe’s Content other than as
          specifically authorised herein, without our prior written permission,
          is strictly prohibited and will terminate the Licence granted herein.
          Such unauthorised use may also violate applicable laws, including
          without limitation copyright and trademark laws and applicable
          communications regulations. Unless explicitly stated herein, nothing
          in these Terms shall be construed as conferring any licence to
          intellectual property rights, whether by estoppel, implication or
          otherwise. This Licence is revocable by us at any time without notice
          and with or without cause.
        </Text>
        <Heading as="h3">Third PARTY Intellectual Property</Heading>
        <Text fontSize="md">
          Where a third party owns the intellectual property, including but not
          limited to copyright, patents or trade secrets, associated with the
          Services (<Text as="b">“Third Party IP”</Text>), you acknowledge and
          agree that:
          <ul>
            <li>
              Provided that you are eligible for use of the Services, subject to
              your compliance with these Terms and applicable laws, and subject
              to section 11 (d), you are granted a worldwide, non-exclusive,
              revocable, non-transferable, non-assignable, non-sub-licensable,
              royalty-free, limited and personal licence in relation to Third
              Party IP solely for your own use in connection with the Services;
            </li>
            <li>
              You may only use the Third Party IP on an “as is” basis, in its
              current condition when purchased; You may not otherwise modify,
              edit or change the Third Party IP;
            </li>
            <li>
              We may pass on additional restrictions based on our licence under
              the Third Party IP to you; and
            </li>
            <li>
              To the extent that we inform you of such additional restrictions
              in writing, you will be responsible for complying with all such
              restrictions from the date that you receive the notice, and that
              failure to do so will be deemed a breach of your Licence.
            </li>
          </ul>
        </Text>
        <Heading as="h3">PAYMENTS, Fees AND TAXES</Heading>
        <Text fontSize="md">
          You are solely responsible for all costs incurred by you in using the
          Services and determining, collecting, reporting and paying all
          applicable Taxes. As used herein, <Text as="b">“Tax”</Text> or{' '}
          <Text as="b">“Taxes”</Text> refers to any and all federal, state,
          local, municipal and national taxes, duties, levies, tariffs and other
          governmental charges, including gross receipts, personal or corporate
          income, profits, sales, use, occupation, goods and services, value
          added, ad valorem, transfer, franchise, withholding, payroll,
          recapture, employment, excise and property taxes, together with all
          interest, penalties and additions imposed with respect to any such
          amounts. The use of our Services may require Platform fees payable to
          the smart contract.
        </Text>
        <Text fontSize="md">
          All payments you make to Joemart, other Users or the Platform under
          these Terms shall be made in full without any deduction or withholding
          (whether in respect of set-off, counter-claim, duties, Tax, bank
          transfer fees, charges or otherwise) unless a deduction or withholding
          is required by any applicable law, in which event you shall:
          <ul>
            <li>
              Ensure that the deduction or withholding does not exceed the
              minimum amount legally required;
            </li>
            <li>
              Be liable to pay to us, such other User or the Platform such
              additional amount that will result in the net amount received by
              us, such other User or the Platform being equal to the amount
              which would have been received had no such deduction or
              withholding been made; and
            </li>
            <li>
              Pay to the relevant Tax authority, within the period for payment
              permitted by any applicable law, the minimum amount of the
              deduction or withholding required by law.
            </li>
          </ul>
        </Text>
        <Text fontSize="md">
          We reserve the right to report any activity occurring using the
          Services to relevant Tax authorities as required under applicable law.
          You are solely responsible for maintaining all relevant Tax records
          and complying with any reporting requirements you may have as related
          to our Services. You are further solely responsible for independently
          maintaining the accuracy of any record submitted to any Tax authority
          including any information derived from the Services.
        </Text>
        <Text fontSize="md">
          We reserve the right to impose any fees, commissions, royalties, and
          other charges (collectively, the “Fees”) at our sole discretion at any
          time for your use of our Services.
        </Text>
        <Text fontSize="md">
          By using our Services, you agree to pay all applicable Fees and you
          authorise us to automatically deduct other Fees (including any gas
          fees or payment processing fees, as applicable) directly from your
          payment.
        </Text>
        <Text fontSize="md">
          All Fees are non-refundable except at the sole discretion of Joemart
          (for Fees within its control) or applicable third parties.
        </Text>
        <Heading as="h3">General Rights</Heading>
        <Text fontSize="md">
          <ul>
            <li>
              <Text as="b">Unlawful Purposes:</Text> Digital Assets used
              primarily or substantially for the purpose of raising funds for
              terrorist organisations are prohibited on the Platform.
            </li>
            <li>
              <Text as="b">Stolen Content</Text> The sale of stolen or otherwise
              illegally obtained Digital Assets on the Platform is prohibited.
              If you have reason to believe that any Digital Asset supported by
              the Platform was illegally obtained, please contact us
              immediately. Transacting illegally obtained Digital Assets may
              result in your access to the Services being suspended.
            </li>
            <li>
              <Text as="b">Monitoring and Investigations</Text> We are not
              obligated to monitor access to or use of the Services or to review
              or edit any Content on the Site or Platform. However, we have the
              right to do so for the purpose of operating the Services, to
              ensure compliance with these Terms and to comply with applicable
              law or other legal requirements. We reserve the right, but are not
              obligated, to remove or disable Users’ access to Services or
              Digital Assets at any time and without notice, including, but not
              limited to, if we, at our sole discretion, consider it
              objectionable or in violation of these Terms. We have the right to
              investigate violations of these Terms or conduct that affects the
              Services.
            </li>
            <li>
              <Text as="b">System Maintenance</Text> We may interrupt your
              access to the Services at our sole discretion for any reason,
              including but not limited to, updating, maintenance,
              malfunctioning equipment or repairing the Services.
            </li>
          </ul>
        </Text>
        <Heading as="h3">Assumption of Risk</Heading>
        <Text fontSize="md">
          <Text as="b">You accept and acknowledge:</Text>
        </Text>
        <Text fontSize="md">
          The price of Digital Assets can fluctuate significantly. In using the
          Services, you may lose all your money that you have used in purchasing
          or otherwise dealing in Digital Assets.
        </Text>
        <Text fontSize="md">
          You have done your research before you decide to purchase, sell,
          transfer, or otherwise interact with Digital Assets, and are using the
          Services at your own risk.
        </Text>
        <Text fontSize="md">
          Digital Assets exist only by virtue of the ownership record maintained
          on its supporting blockchain. Any transfer of title occurs on the
          decentralised ledger within such blockchain network. We do not
          guarantee that we can effect the transfer of title or right in any
          Digital Assets. Transactions in Digital Assets may be irreversible,
          and, accordingly, losses due to fraudulent or accidental transactions
          may not be recoverable. Transactions in Digital Assets may be deemed
          to be made when recorded on a public ledger, which is not necessarily
          the date or time that you initiated the transaction.
        </Text>
        <Text fontSize="md">
          The Services may be temporarily unavailable from time to time for
          maintenance or other reasons. Joemart will not be responsible for any
          communication failures, disruptions, errors, distortions or delays you
          may experience when using the Services, however caused.
        </Text>
        <Text fontSize="md">
          Joemart will not be responsible for any risks to you from using the
          Services, which include, but are not limited to:
          <ul>
            <li>Non-recoverable and/or non-reversible transactions;</li>
            <li>Fraudulent and/or fraudulent transactions;</li>
            <li>
              Unauthorised third-party access such as malicious software,
              hacking, theft, attacks;
            </li>
            <li>Hardware, software and/or internet connection failure;</li>
            <li>
              Changes to the protocol or network of the underlying blockchain
              including changes in operating rules (<Text as="b">“Forks”</Text>)
              which may affect the availability, value, functionality and/or
              name of a Digital Asset;
            </li>
            <li>
              Regulatory changes which may adversely affect the development of
              the Services and the use of Digital Assets;
            </li>
            <li>
              A lack of public interest in the Services which may also
              negatively impact the potential utility or value of Digital
              Assets; or
            </li>
            <li>
              Changes affecting, or decisions taken by, third-party platforms
              which may support or perform transactions with respect to our
              Services.
            </li>
          </ul>
        </Text>
        <Text fontSize="md">
          We are not providing any financial, legal, tax, accounting or other
          professional advice. Nothing from our Services, including any Content
          on the Platform, should be construed as financial, legal, tax,
          accounting or other professional advice. If you are in doubt, please
          consult an independent professional advisor from persons licensed and
          qualified in the area for which such advice would be appropriate.
        </Text>
        <Heading as="h3">Links to Third PARTY Websites or Content</Heading>
        <Text fontSize="md">
          The Services may allow you to access third-party websites or
          third-party Content. We provide access only as a convenience and are
          not responsible for the Content, products or services on or available
          from those resources or links displayed on such websites. You
          acknowledge sole responsibility for, and assume all risk arising from,
          your use of any third-party websites or Content.
        </Text>
        <Text fontSize="md">
          References to any other products, services, Content, processes or
          other information by name, trademark, manufacturer, supplier or
          otherwise on the Service does not constitute or imply endorsement,
          sponsorship or recommendation by Joemart.
        </Text>
        <Heading as="h3">Disclaimers</Heading>
        <Text fontSize="md">
          If you elect to sell, purchase, or trade Digital Assets, any
          transactions that you engage in will be conducted solely through the
          blockchain network governing such Digital Asset and you will be
          required to make or receive payments exclusively through your Wallet.
          We will have no control over these payments or transactions, nor do we
          have the ability to reverse any transactions. Accordingly, we will
          have no liability to you or to any third party for any claims or
          damages that may arise as a result of any transactions that you enter
          into in relation to the Service.
        </Text>
        <Text fontSize="md">
          Your use of the Services is at your own risk. You understand and agree
          that the Services provided, including any Digital Assets listed
          therein, are provided on an “as is” and “as available” basis without
          warranties or conditions of any kind, either express or implied.
          Joemart (and its service providers) make no representation or
          warranty:
          <ul>
            <li>That the Services will meet your requirements;</li>
            <li>
              That the Services will be available on an uninterrupted, timely,
              secure, or error-free basis;
            </li>
            <li>
              That the Services or Trader Joe’s Content are lawful, accurate,
              complete, reliable, safe, error-free, free of other viruses or
              other harmful components;
            </li>
            <li>
              As to the value, fairness of price or value of use of any Digital
              Assets available for sale on the Platform or subsequently made
              available for sale on any third-party platform;
            </li>
            <li>
              Of title, origin, non-infringement, functionality,
              merchantability, usage, security, suitability or fitness for any
              particular purpose, workmanship or technical quality of any
              Digital Asset; or
            </li>
            <li>
              About the identity, legitimacy, authenticity or legal rights of
              Users to acquire ownership, resell or otherwise deal with Digital
              Assets available on the Platform.
            </li>
          </ul>
        </Text>
        <Text fontSize="md">
          Joemart disclaims all other warranties or conditions, express or
          implied, including, without limitation, any implied warranty of
          merchantability, fitness for a particular purpose or non-infringement,
          to the maximum extent permitted by applicable law, as to the Service
          and Trader Joe’s Content.
        </Text>
        <Text fontSize="md">
          We will not be responsible or liable to you for any loss in relation
          to your use of Services, or Digital Assets, or your disclosure of
          Content online, including but not limited to any losses, damages or
          claims arising from:
          <ul>
            <li>
              User error such as forgotten passwords, incorrectly constructed
              transactions, or mistyped addresses;
            </li>
            <li>
              The purchase or inability to purchase Digital Assets on the
              Platform; Compromised or hacked linked Wallets;
            </li>
            <li>Server failure or data loss; Corrupted Wallet files;</li>
            <li>Unauthorised access to applications;</li>
            <li>
              Any unauthorised third-party activities or breaches of security,
              including the use of viruses, phishing, bruteforcing or other
              means of attack against the Services; or
            </li>
            <li>
              Issues with the blockchain supporting Digital Assets, including
              Forks, technical node issues, repudiated transactions, migrations
              or updates.
            </li>
          </ul>
        </Text>
        <Text fontSize="md">
          Joemart will not be responsible or liable to you for any losses when
          we decide to sell or purchase any quantity of Digital Assets at our
          sole discretion.
        </Text>
        <Text fontSize="md">
          Some jurisdictions do not allow the exclusion of implied warranties in
          contracts with consumers, so the above exclusion may not fully apply
          to you.
        </Text>
        <Text fontSize="md">
          Nothing in these Terms shall exclude or limit liability of either
          party for fraud, violation of laws, or any other activity that cannot
          be limited or excluded by legitimate means.
        </Text>
        <Heading as="h3">Limitation of Liability</Heading>
        <Text fontSize="md">
          To the maximum extent permitted by law, neither Joemart, nor its
          service providers involved in creating, producing, or delivering the
          Services will be liable for: any incidental, special, punitive,
          exemplary or consequential damages, or damages for lost profits, lost
          revenues, lost savings, lost business opportunity, loss of data or
          goodwill, service interruption, computer damage or system failure or
          the cost of substitute services of any kind arising out of or in
          connection with these Terms or from the use of or inability to use the
          Services, whether based on warranty, contract, tort (including
          negligence), product liability or any other legal theory, and whether
          or not Joemart or its service providers has been informed of, or could
          foresee, the possibility of such damage.
        </Text>
        <Text fontSize="md">
          To the maximum extent permitted by law, neither Joemart nor its
          service providers will be liable for any damages to your computer
          system, mobile device or data from the use of the Service and any
          third-party sites.
        </Text>
        <Text fontSize="md">
          To the maximum extent permitted by the law of the applicable
          jurisdiction, in no event will Joemart’s total liability, arising out
          of or in connection with these Terms or from your use of or inability
          to use the Services, exceed the amounts you have paid or are payable
          by you to Joemart for use of the Services, or one hundred US Dollars
          (USD 100) if you have not had any payment obligations to Joemart, as
          applicable.
        </Text>
        <Text fontSize="md">
          The exclusions and limitations of damages set forth above are
          fundamental elements of the basis of the bargain between Joemart and
          you.
        </Text>
        <Heading as="h3">Indemnity</Heading>
        <Text fontSize="md">
          To the fullest extent permitted by applicable law, you agree to
          indemnify, defend and hold harmless Joemart, and our respective past,
          present and future Affiliates, employees, officers, directors,
          contractors, consultants, equity holders, suppliers, vendors, service
          providers, parent companies, subsidiaries, agents, representatives,
          predecessors, successors and assigns, from and against all actual or
          alleged third-party claims, damages, awards, judgments, losses,
          liabilities, obligations, penalties, interest, fees, expenses
          (including, without limitation, attorneys’ fees and expenses) and
          costs (including, without limitation, court costs, costs of settlement
          and costs of pursuing indemnification and insurance), of every kind
          and nature whatsoever, whether known or unknown, foreseen or
          unforeseen, matured or unmatured, or suspected or unsuspected, in law
          or equity, whether in tort, contract or otherwise (collectively,
          “Claims”), including, but not limited to, damages to property or
          personal injury, that are caused by, arise out of or are related to:
          <ul>
            <li>Your use or misuse of the Services;</li>
            <li>Your violation of these Terms;</li>
            <li>Your use or disposal of any Digital Assets;</li>
            <li>Your negligence or willful misconduct; or</li>
            <li>
              Your violation of the rights of a third party or of applicable
              law.
            </li>
          </ul>
        </Text>
        <Text fontSize="md">
          You agree to promptly notify us of any Claims and cooperate with us in
          defending such Claims. You further agree that Joemart shall have
          control of the defense or settlement of any third-party Claims. This
          indemnity is in addition to, and not in lieu of, any other indemnities
          set forth in any written agreement between you and Joemart.
        </Text>
        <Heading as="h3">No conflict with local law</Heading>
        <Text fontSize="md">
          The laws of some jurisdictions may limit or not permit certain
          provisions of this agreement, such as indemnification, the exclusion
          of certain warranties or the limitation of liability. In such a case,
          such provisions will apply only to the maximum extent permitted by the
          laws of such jurisdictions. Also, you may have additional legal rights
          in your jurisdiction, and nothing in these Terms will prejudice such
          rights that you may have as a consumer of the Services under such
          applicable law.
        </Text>
        <Heading as="h3">SUSPENSION AND Termination</Heading>
        <Text fontSize="md">
          We may suspend or terminate your access to the Services at any time in
          connection with any transaction as required by applicable law, any
          governmental authority, or if we in our sole and reasonable discretion
          determine you are violating these Terms or the terms of any
          third-party service provider. Such suspension or termination shall not
          be construed as a breach of these Terms by Joemart. In accordance with
          its anti-money laundering, anti-terrorism, anti-fraud, and other
          compliance policies and practices, we may impose reasonable
          limitations and controls on the ability of you or any beneficiary to
          utilise the Services. Such limitations may include where good cause
          exists, rejecting transaction requests, freezing funds, or otherwise
          restricting you from using the Services. We also reserve the right to
          cooperate with law enforcement authorities and disclose any
          information in our possession or from our Services that we deem
          necessary to meet any applicable laws, regulations or legal processes.
        </Text>
        <Text fontSize="md">
          You acknowledge and agree that we shall have no liability or
          obligation to you in such event that we suspend or terminate your
          access to the Services, and that you will not be entitled to a refund
          of any amounts that you have already paid to us. Upon any termination
          of these Terms, all sections which by their nature should survive the
          termination of these Terms shall continue in full force and effect
          subsequent to and notwithstanding such termination. Termination will
          not limit any of our other rights or remedies at law or in equity.
        </Text>
        <Heading as="h3">Governing Law</Heading>
        <Text fontSize="md">
          These Terms and any action related thereto will be governed by the
          laws of Singapore, without regard to conflict of laws of Singapore or
          any other jurisdiction.
        </Text>
        <Heading as="h3">Dispute Resolution</Heading>
        <Text fontSize="md">
          <Text as="b">Mandatory Arbitration of Disputes</Text>
        </Text>
        <Text fontSize="md">
          You and Joemart each agree that any dispute, claim or controversy
          arising out of or relating to these Terms or the breach, termination,
          enforcement, interpretation or validity thereof or the use of the
          Services (collectively, <Text as="b">“Disputes”</Text>) shall be
          referred to and finally resolved by arbitration administered will be
          resolved solely by the Singapore International Arbitration Centre (
          <Text as="b">“SIAC”</Text>) in accordance with the Arbitration Rules
          of the Singapore International Arbitration Centre (
          <Text as="b">“SIAC Rules”</Text>) for the time being in force, which
          rules are deemed to be incorporated by reference in this provision.
          The seat of arbitration shall be Singapore. The Tribunal shall consist
          of one arbitrator. The language of arbitration shall be English. Any
          arbitration hearings will take place in the country where you live,
          unless we both agree to a different location, but will be conducted
          remotely to the extent permitted by the SIAC Rules. The parties agree
          that the arbitrator shall have exclusive authority to decide all
          issues relating to the interpretation, applicability, enforceability
          and scope of this arbitration agreement. This arbitration provision
          shall survive termination of these Terms.
        </Text>
        <Heading as="h3">Exceptions</Heading>
        <Text fontSize="md">
          As limited exceptions to section 22.1 above:
          <ul>
            <li>
              You and Joemart both may seek to resolve a Dispute in a small
              claims court if it qualifies, provided the claims remain only in
              such court; and
            </li>
            <li>
              You and Joemart each retain the right to seek injunctive or other
              equitable relief from a court to prevent (or enjoin) the
              infringement or misappropriation of our intellectual property
              rights.
            </li>
          </ul>
        </Text>
        <Heading as="h3">Good faith resolution</Heading>
        <Text fontSize="md">
          You and Joemart agree to resolve disputes in good faith prior to
          either party initiating an arbitration, small claims court proceeding
          or equitable relief for intellectual property infringement. If the
          parties do not reach agreement to resolve the dispute within thirty
          (30) days after initiation of this dispute resolution process, either
          party may commence arbitration, file an action in small claims court,
          or file a claim for injunctive or equitable relief in a court of
          proper jurisdiction for matters relating to intellectual property
          infringement, if the claims qualify.
        </Text>
        <Heading as="h3">Arbitration Costs</Heading>
        <Text fontSize="md">
          Payment of all filing, administration and arbitrator fees will be
          governed by the SIAC Rules, and we will not seek to recover the
          administration and arbitrator fees we are responsible for paying,
          unless the arbitrator finds your Dispute frivolous. If you prevail in
          arbitration you may be entitled to an award of attorneys’ fees and
          expenses to the extent provided under applicable law.
        </Text>
        <Heading as="h3">Class Action Waiver</Heading>
        <Text fontSize="md">
          You and Joemart each waive the right to a trial by jury or to
          participate in a class, representative or consolidated action or
          proceeding. Further, if the parties’ Dispute is resolved through
          arbitration, the arbitrator may not consolidate another person’s
          claims with your claims, and may not otherwise preside over any form
          of a representative or class proceeding, without the written
          permission of all parties involved.
        </Text>
        <Heading as="h3">General Terms</Heading>
        <Heading as="h3">Our right to purchase Digital Assets</Heading>
        <Text fontSize="md">
          We reserve the right to sell or purchase any quantity of Digital
          Assets at our sole discretion. We are not required to warn any User or
          third party when we decide to exercise this right.
        </Text>
        <Heading as="h3">Our right to block access</Heading>
        <Text fontSize="md">
          We reserve the right, at any time, in our sole discretion, to block
          access to the Services from certain IP addresses and unique device
          identifiers.
        </Text>
        <Heading as="h3">Entire Agreement</Heading>
        <Text fontSize="md">
          These Terms constitute the entire and exclusive understanding and
          agreement between Joemart and you regarding the Services, and these
          Terms supersede and replace all prior oral or written understandings
          or agreements between Joemart and you regarding the Services.
        </Text>
        <Heading as="h3">Assignability</Heading>
        <Text fontSize="md">
          You may not assign or transfer these Terms, by operation of law or
          otherwise, without our prior written consent. Any attempt by you to
          assign or transfer these Terms, without such consent, will be null.
          Joemart may freely assign or transfer these Terms without restriction.
          Subject to the foregoing, these Terms will bind and inure to the
          benefit of the parties, their successors and permitted assigns.
        </Text>
        <Heading as="h3">No Waiver</Heading>
        <Text fontSize="md">
          No waiver by either party of any breach or default hereunder shall be
          deemed to be a waiver of any preceding or subsequent breach or
          default.
        </Text>
        <Heading as="h3">Severability</Heading>
        <Text fontSize="md">
          If an arbitrator or court of competent jurisdiction decides that any
          part of these Terms is invalid or unenforceable, then the invalid or
          unenforceable part will be severed from the Terms while the remaining
          parts of these Terms will remain in full force and effect.
        </Text>
        <Heading as="h3">Notices and communications</Heading>
        <Text fontSize="md">
          By using our Services, you consent to receiving electronic
          communications from us (including messages on third party platforms,
          Platform messages, or other types of messages).
        </Text>
        <Text fontSize="md">
          Any notices or other communications provided by Joemart under these
          Terms will be given by posting on the Platform, on our official
          Discord channel, on our official Twitter page or any other platform
          that we may inform you.
        </Text>
        <Text fontSize="md">
          Communication made by posting on the Platform or on any other platform
          will constitute written notice for the purposes of these Terms when
          the message is recorded to be posted on such platform by system
          record, except where the time of posting is not between 09:00am and
          5:00pm on a day on which business is generally carried on in the place
          to which such notice is sent, in which case the notice will be deemed
          to have been received at the commencement of business on the next such
          day in that place.
        </Text>
        <Heading as="h3">Waiver of Rights</Heading>
        <Text fontSize="md">
          Joemart’s failure to enforce any right or provision of these Terms
          will not be considered a waiver of such right or provision. The waiver
          of any such right or provision will be effective only if in writing
          and signed by a duly authorised representative of Joemart. Except as
          expressly set forth in these Terms, the exercise by either party of
          any of its remedies under these Terms will be without prejudice to its
          other remedies under these Terms or otherwise.
        </Text>
        <Heading as="h3">Force Majeure</Heading>
        <Text fontSize="md">
          Joemart shall not be liable for any action, omission, duty or
          obligation due to any occurrence that is beyond our control,
          including:
          <ul>
            <li>any future laws or regulations;</li>
            <li>any act of God or war;</li>
            <li>terrorism;</li>
            <li>any epidemic or pandemic;</li>
            <li>hacking or other attack on the Services;</li>
            <li>
              the unavailability, disruption or malfunction of any network or
              blockchains;
            </li>
            <li>
              the unavailability, disruption or malfunction of the Internet; or
            </li>
            <li>
              any unavailability, disruption or malfunction of any system,
              software, network or infrastructure necessary for the Services to
              function.
            </li>
          </ul>
        </Text>
        <Heading as="h3">CONTACT AND Feedback</Heading>
        <Text fontSize="md">
          If you have any questions about these Terms or the Services, please
          contact us at our official Discord channel. We welcome feedback and
          suggestions for our Services. You agree that any feedback provided to
          us can be used at our discretion in any manner, without notice and
          without compensation to you.
        </Text>
      </Flex>
    </>
  )
}

export default TermsOfService
