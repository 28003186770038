import { HStack, Link, LinkProps } from '@chakra-ui/react'
import React from 'react'
import {
  CoinMarketCapIcon,
  DiscordIcon,
  GithubIcon,
  SubstackIcon,
  TelegramIcon,
  XIcon
} from 'theme/icons'

const SocialNetworkLink = (props: LinkProps) => (
  <Link
    isExternal
    display="flex"
    alignItems="center"
    justifyContent="center"
    borderRadius="2xl"
    bg="bgSecondary"
    boxSize="48px"
    _hover={{ bg: 'hover' }}
    {...props}
  />
)

const SocialNetworksSection = () => {
  return (
    <HStack>
      <SocialNetworkLink
        aria-label="LFJ on Discord"
        href="https://discord.gg/lfj"
      >
        <DiscordIcon boxSize="28px" fill="textSecondary" />
      </SocialNetworkLink>
      <SocialNetworkLink aria-label="LFJ on X" href="https://x.com/LFJ_gg">
        <XIcon boxSize="18px" fill="textSecondary" />
      </SocialNetworkLink>
      <SocialNetworkLink
        aria-label="LFJ on Substack"
        href="https://joecontent.substack.com/"
      >
        <SubstackIcon boxSize="28px" fill="textSecondary" />
      </SocialNetworkLink>
      <SocialNetworkLink
        aria-label="LFJ on CoinMarketCap"
        href="https://coinmarketcap.com/community/profile/LFJ_gg/"
      >
        <CoinMarketCapIcon boxSize="22px" fill="textSecondary" />
      </SocialNetworkLink>
      <SocialNetworkLink
        aria-label="LFJ on Github"
        href="https://github.com/traderjoe-xyz/"
      >
        <GithubIcon boxSize="28px" fill="textSecondary" />
      </SocialNetworkLink>
      <SocialNetworkLink
        aria-label="LFJ on Telegram"
        href="https://t.me/lfj_gg"
      >
        <TelegramIcon boxSize="28px" fill="textSecondary" />
      </SocialNetworkLink>
    </HStack>
  )
}

export default SocialNetworksSection
