import { ChainId } from '@traderjoe-xyz/sdk-core'

interface StrategyInfo {
  name: string
  vault: string
}

export const FARM_HELMET_TITLE =
  'LFJ | Instant, Low-Cost Crypto Exchange for All Traders | Farm'
export const FARM_HELMET_DESCRIPTION =
  "Let's F***ing Joe (formerly Trader Joe) is a leading decentralized exchange. Fast, low-cost trading. Simple solutions for everyday traders. Access yield farms to boost earning potential."

export const DISABLED_VAULTS: {
  [chainId in Exclude<ChainId, ChainId.MANTLE>]: string[]
} = {
  [ChainId.FUJI]: [],
  [ChainId.ARBITRUM_ONE]: [
    '0x7f17620836e55f97f3858cb46034e1213907cce1',
    '0xFd421D60905d2f7caBd49e6A5703A3499367B8F4',
    '0xa37eF01065E0328b50A85256E159b9aaed196e05'
  ],
  [ChainId.ARB_GOERLI]: [],
  [ChainId.ETHEREUM]: [],
  [ChainId.BNB_TESTNET]: [],
  [ChainId.BNB_CHAIN]: ['0x1a83dc9533dd1e0dccdb51a7505e0b42f679d210'],
  [ChainId.AVALANCHE]: ['0x160cc83a3f77726a33b685b69bfd0b1daa06e579']
}

export const STRATEGIES_INFO: {
  [chainId in Exclude<ChainId, ChainId.MANTLE>]: {
    [address: string]: StrategyInfo | undefined
  }
} = {
  [ChainId.FUJI]: {},
  [ChainId.AVALANCHE]: {
    // AVAX-USDC-20
    '0x0ddba20fa3b247fb3381cde1a1fae35c032e33fc': {
      name: 'Auto Pool',
      vault: '0x32833a12ed3fd5120429fb01564c98ce3c60fc1d'
    },
    // BTC.b-AVAX-10
    '0x368946d86f1a6d050f7ab2e04471025c1d762342': {
      name: 'Auto Pool',
      vault: '0x1c739a43606794849750c50bc7c43fbbdacdf801'
    },

    // EURC-USDC-5
    '0x8efa72ced215b58fa767b42ff84faff054c1dcb7': {
      name: 'Auto Pool',
      vault: '0x052af5b8ac73082d8c4c8202bb21f4531a51dc73'
    },

    // sAVAX-AVAX-5
    '0x9d870f63673d739871071cd5b4275bb791512325': {
      name: 'Auto Pool',
      vault: '0xf812a978a08f370b9ab358a620377c0a261aa403'
    },

    // WETH-AVAX-10
    '0xb409942d7d93be0b1f4f93bfa714574669ef9995': {
      name: 'Auto Pool',
      vault: '0x6178de6e552055862cf5c56310763eec0145688d'
    },

    // USDT-USDC-1
    '0xf328f109e13f879753df629f98b39ef144a23e74': {
      name: 'Auto Pool',
      vault: '0x160cc83a3f77726a33b685b69bfd0b1daa06e579'
    }
  },
  [ChainId.ARBITRUM_ONE]: {
    // ARB-ETH-20
    '0x9308b82ddc69865bc722b451a232a8c78418d3b7': {
      name: 'Auto Pool',
      vault: '0x7f17620836e55f97f3858cb46034e1213907cce1'
    },

    // WBTC-ETH-10
    '0x9a39aab7c94a83493a70a39dfc0952ce7fabcda7': {
      name: 'Auto Pool',
      vault: '0xea9f1ab0ccdb6baf36ce8567d65f354f2945f0da'
    },

    // ETH-USDC-15
    '0xe09a90f2e218420feda16194fe06092b1bd2fa97': {
      name: 'Auto Pool',
      vault: '0xfd421d60905d2f7cabd49e6a5703a3499367b8f4'
    }
  },
  [ChainId.ARB_GOERLI]: {},
  [ChainId.BNB_CHAIN]: {
    '0x3ff32be73a417330d6b07a7f31b345053b19a0db': {
      name: 'Auto Pool',
      vault: '0x1a83dc9533dd1e0dccdb51a7505e0b42f679d210'
    }
  },
  [ChainId.BNB_TESTNET]: {},
  [ChainId.ETHEREUM]: {}
}

// these auto-pools will use emergencyWithdraw instead of queueWithdrawal
export const CLOSED_AUTO_POOLS: {
  [chainId in Exclude<ChainId, ChainId.MANTLE>]: string[]
} = {
  [ChainId.ARBITRUM_ONE]: [
    '0x7f17620836e55f97f3858cb46034e1213907cce1',
    '0xFd421D60905d2f7caBd49e6A5703A3499367B8F4',
    '0xa37eF01065E0328b50A85256E159b9aaed196e05'
  ],
  [ChainId.ARB_GOERLI]: [],
  [ChainId.AVALANCHE]: ['0x160cc83a3f77726a33b685b69bfd0b1daa06e579'],
  [ChainId.BNB_TESTNET]: [],
  [ChainId.BNB_CHAIN]: ['0x1a83dc9533dd1e0dccdb51a7505e0b42f679d210'],
  [ChainId.ETHEREUM]: [],
  [ChainId.FUJI]: []
}
