import { ChainId } from '@traderjoe-xyz/sdk-core'

export const getMaxBinPerAddLiquidityBatch = (
  chainId: Exclude<ChainId, ChainId.MANTLE>
) => {
  switch (chainId) {
    case ChainId.ARBITRUM_ONE:
    case ChainId.ARB_GOERLI:
      return 150
    case ChainId.AVALANCHE:
    case ChainId.FUJI:
      return 150
    case ChainId.BNB_CHAIN:
    case ChainId.BNB_TESTNET:
      return 200
    case ChainId.ETHEREUM:
      return 100
  }
}

export const getMaxBinPerRemoveLiquidityBatch = (
  chainId: Exclude<ChainId, ChainId.MANTLE>
) => {
  switch (chainId) {
    case ChainId.ARBITRUM_ONE:
    case ChainId.ARB_GOERLI:
      return 200
    case ChainId.AVALANCHE:
    case ChainId.FUJI:
      return 200
    case ChainId.BNB_CHAIN:
    case ChainId.BNB_TESTNET:
      return 200
    case ChainId.ETHEREUM:
      return 200
  }
}
