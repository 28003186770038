import {
  LiquidityDistribution,
  LiquidityDistributionParams
} from '@traderjoe-xyz/sdk-v2'
import { ADD_LIQUIDITY_V2_DEFAULT_RADIUS } from 'constants/pool'
import { GetBalanceData } from 'wagmi/query'

export const DEFAULT_DISTRIBUTION = LiquidityDistribution.SPOT

export const getDefaultBinRange = (activeBinId: number | undefined) => {
  return activeBinId
    ? [
        activeBinId - ADD_LIQUIDITY_V2_DEFAULT_RADIUS,
        activeBinId + ADD_LIQUIDITY_V2_DEFAULT_RADIUS
      ]
    : undefined
}

type ImportedDistribution = {
  account: string
  amountXPerYRatio: number
  distributionParams: LiquidityDistributionParams
  percentOfFundsToUse: number
}

export type AddLiquidityState = {
  amount0: string
  amount1: string
  distribution: LiquidityDistribution
  activeBinId?: number
  binRange?: number[]
  importedDistribution?: ImportedDistribution
}

export type AddLiquidityAction =
  | {
      payload: {
        balance0?: GetBalanceData
        balance1?: GetBalanceData
        distribution?: ImportedDistribution
      }
      type: 'set_imported_distribution'
    }
  | { payload: string; type: 'set_amount0' }
  | { payload: string; type: 'set_amount1' }
  | {
      type: 'set_bin_range'
      payload?: number[]
    }
  | { type: 'set_default_bin_range' }
  | {
      payload: LiquidityDistribution
      type: 'set_distribution'
    }
  | { type: 'unset_imported_distribution' }
  | { type: 'set_active_bin_id'; payload?: number }
  | { type: 'reset' }

export const addLiquidityStateReducer = (
  state: AddLiquidityState,
  action: AddLiquidityAction
): AddLiquidityState => {
  switch (action.type) {
    case 'set_imported_distribution':
      const { balance0, balance1, distribution } = action.payload || {}
      const activeBinId = state.activeBinId
      if (!distribution || !activeBinId) {
        return state
      }

      const numBalance0 = balance0 ? Number(balance0.formatted) : undefined
      const numBalance1 = balance1 ? Number(balance1.formatted) : undefined

      const hasNegativeDeltaIds = distribution.distributionParams.deltaIds.some(
        (value) => value <= 0
      )
      const hasPositiveDeltaIds = distribution.distributionParams.deltaIds.some(
        (value) => value >= 0
      )

      let amount0: number | undefined
      let amount1: number | undefined
      if (numBalance0 && numBalance1) {
        if (hasNegativeDeltaIds && hasPositiveDeltaIds) {
          const { amountXPerYRatio } = distribution
          amount0 = numBalance0
          amount1 = amount0 / amountXPerYRatio

          if (amount1 > numBalance1) {
            amount1 = numBalance1
            amount0 = amount1 * amountXPerYRatio
          }
        } else if (hasNegativeDeltaIds) {
          amount0 = 0
          amount1 = numBalance1
        } else if (hasPositiveDeltaIds) {
          amount0 = numBalance0
          amount1 = 0
        }
      }

      const percentOfFundsToUse = distribution.percentOfFundsToUse
      amount0 = amount0 ? amount0 * percentOfFundsToUse : amount0
      amount1 = amount1 ? amount1 * percentOfFundsToUse : amount1

      const deltaIds = distribution.distributionParams.deltaIds
      return {
        ...state,
        amount0:
          amount0?.toFixed(balance0?.decimals).replace(/\.0+$/, '') ||
          state.amount0,
        amount1:
          amount1?.toFixed(balance1?.decimals).replace(/\.0+$/, '') ||
          state.amount1,
        binRange: [
          activeBinId + deltaIds[0],
          activeBinId + deltaIds[deltaIds.length - 1]
        ],
        importedDistribution: distribution
      }
    case 'unset_imported_distribution':
      return {
        ...state,
        amount0: '',
        amount1: '',
        binRange: getDefaultBinRange(state.activeBinId),
        importedDistribution: undefined
      }
    case 'set_amount0':
      return {
        ...state,
        amount0: action.payload
      }
    case 'set_amount1':
      return {
        ...state,
        amount1: action.payload
      }
    case 'set_distribution':
      return {
        ...state,
        distribution: action.payload
      }
    case 'set_bin_range': {
      const range = action.payload
      const activeBinId = state.activeBinId
      return {
        ...state,
        amount0:
          activeBinId && range && range[1] < activeBinId ? '0' : state.amount0,
        amount1:
          activeBinId && range && activeBinId < range[0] ? '0' : state.amount1,
        binRange: range
      }
    }
    case 'set_default_bin_range':
      return {
        ...state,
        binRange: getDefaultBinRange(state.activeBinId)
      }
    case 'set_active_bin_id':
      return {
        ...state,
        activeBinId: action.payload,
        binRange: getDefaultBinRange(action.payload)
      }
    case 'reset':
      return {
        ...state,
        amount0: '',
        amount1: '',
        binRange: getDefaultBinRange(state.activeBinId),
        importedDistribution: undefined
      }
  }
}
