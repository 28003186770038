import binanceWallet from '@binance/w3w-rainbow-connector-v2'
import { connectorsForWallets } from '@rainbow-me/rainbowkit'
import {
  bitgetWallet,
  braveWallet,
  coinbaseWallet,
  coreWallet,
  injectedWallet,
  metaMaskWallet,
  okxWallet,
  rabbyWallet,
  safeWallet,
  trustWallet,
  walletConnectWallet
} from '@rainbow-me/rainbowkit/wallets'
import { ChainId } from '@traderjoe-xyz/sdk-core'
import { supportedChains } from 'constants/chains'
import store from 'state/store'
import { isRunningE2ETests, wagmiTestConfig } from 'utils/e2e'
import { http } from 'viem'
import { createConfig, fallback } from 'wagmi'

const poktPortalId = process.env.REACT_APP_POKT_PORTAL_ID ?? ''
const walletConnectProjectId =
  process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID ?? ''

const connectors = connectorsForWallets(
  [
    {
      groupName: 'Recommended',
      wallets: [
        coreWallet,
        coinbaseWallet,
        metaMaskWallet,
        binanceWallet,
        walletConnectWallet,
        trustWallet,
        braveWallet,
        injectedWallet,
        rabbyWallet,
        okxWallet,
        safeWallet,
        bitgetWallet
      ]
    }
  ],
  { appName: `LFJ`, projectId: walletConnectProjectId }
)

const isDedicatedRPC = store.getState().settings.isDedicatedRPC

export const wagmiConfig = isRunningE2ETests
  ? wagmiTestConfig
  : createConfig({
      batch: { multicall: true },
      chains: supportedChains,
      connectors,
      transports: isDedicatedRPC
        ? {
            [ChainId.AVALANCHE]: fallback([
              http(`https://avax-mainnet.rpc.grove.city/v1/${poktPortalId}`),
              http()
            ]),
            [ChainId.ARBITRUM_ONE]: fallback([
              http(`https://arbitrum-one.rpc.grove.city/v1/${poktPortalId}`),
              http()
            ]),
            [ChainId.BNB_CHAIN]: fallback([
              http(`https://bsc-mainnet.rpc.grove.city/v1/${poktPortalId}`),
              http()
            ]),
            [ChainId.ETHEREUM]: fallback([
              http(`https://eth-mainnet.rpc.grove.city/v1/${poktPortalId}`),
              http()
            ]),
            [ChainId.ARB_GOERLI]: http(),
            [ChainId.BNB_TESTNET]: http(),
            [ChainId.FUJI]: http()
          }
        : {
            [ChainId.AVALANCHE]: http(),
            [ChainId.ARBITRUM_ONE]: http(),
            [ChainId.BNB_CHAIN]: http(),
            [ChainId.ETHEREUM]: http(),
            [ChainId.ARB_GOERLI]: http(),
            [ChainId.BNB_TESTNET]: http(),
            [ChainId.FUJI]: http()
          }
    })
